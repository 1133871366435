// This file contains DataObject store used with vue. Previously this was in o365.vue.ts
import type { DataObjectOptions, DataObjectMetaOptions } from './types.ts';
import type { Ref } from 'vue';
import DataObject from './DataObject.ts';
import { getAppConfig, getExternalAppConfig, initializeConfigs } from 'o365-modules';
import { ref } from 'vue';

await initializeConfigs();

const config = getAppConfig();

export const dataObjectStore = new Map<string, Map<string, Ref<DataObject>>>();

function checkStoreScope(pAppId = 'site') {
    if (!dataObjectStore.has(pAppId)) {
        dataObjectStore.set(pAppId, new Map())
    }
}

export function createDataObject(pOptions: DataObjectOptions, pMetaOptions?: DataObjectMetaOptions): DataObject {
    if (!pOptions.appId) {
        pOptions.appId = 'site';
    }
    checkStoreScope(pOptions.appId);
    // const dataObject = ref(new DataObject(pOptions, pMetaOptions)) as Ref<DataObject>;
    //dataObjectStore.get(pOptions.appId)?.set(pOptions.id, dataObject);
    dataObjectStore.get(pOptions.appId)?.set(pOptions.id, ref(new DataObject(pOptions, pMetaOptions)) as Ref<DataObject>);
    dataObjectStore.get(pOptions.appId)!.get(pOptions.id)!.value.initializeExtensions(pOptions);

    return dataObjectStore.get(pOptions.appId)!.get(pOptions.id)!.value;
}

export function initDataObjectFromConfig(pId: string, pAppId = 'site'): void {
    let appConfig: Partial<typeof config> | undefined = undefined;
    if (pAppId === 'site' || config.id === pAppId) {
        appConfig = config;
    } else {
        appConfig = getExternalAppConfig(pAppId) as any;
    }
    if (appConfig == null) {
        throw new Error(`No config found for app ${pAppId}`);
    }

    let options: any = appConfig.dataObjectConfigs?.get(pId);
    if (options == null) {
        throw new Error(`DataObject with Id ${pId} does not exist in ${pAppId}`);
    }
    options = { ...options };
    if (!options.appId) {
        options.appId = pAppId;
    }

    options.viewDefinition = appConfig.viewDefinitions != null ? appConfig.viewDefinitions[options.viewName] : null;
    options.uniqueTableDefinition = appConfig.viewDefinitions != null ? appConfig.viewDefinitions[options.uniqueTable] : null;


    createDataObject(options, { isFromDesigner: true });
}

export function deleteDataObject(pId: string, pAppId = 'site'): void {
    if (dataObjectStore.get(pAppId)?.has(pId)) {
        dataObjectStore.get(pAppId)!.get(pId)!.value.destroyDataObject();
        dataObjectStore.get(pAppId)!.delete(pId);
    }
}

export function resetDataObject(pId: string, pAppId = 'site') {
    const dataObject = getDataObjectById(pId, pAppId);
    if (dataObject == null) { throw new Error(`DataObject with ID ${pId} does not exist in ${pAppId}`); }
    dataObject.unsetCurrentIndex();
    const details = dataObject.masterDetails?.getDetailsOptions();
    deleteDataObject(pId, pAppId);
    if (details.length) {
        details.forEach(detail => {
            const detailDataObject = getDataObjectById(detail.id, pAppId);
            detailDataObject.setMasterDetails(detail.definition, pId);
        });
    }
    return getDataObjectById(pId, pAppId);
}

/** Data object item models */
export interface DataObjectModels { };

export function getOrCreateDataObject<T extends object = any>(pOptions: DataObjectOptions, pAppId: string = 'site'): DataObject<T> {
    if (!pOptions.appId) {
        pOptions.appId = pAppId;
        if (pOptions.disableLayouts !== false) {
            // Disable layouts for dynamic dataobjects from sitesetup files
            pOptions.disableLayouts = true;
        }
    }
    checkStoreScope(pOptions.appId);

    if (!dataObjectStore.get(pOptions.appId)!.has(pOptions.id)) {
        return createDataObject(pOptions) as any;
    }

    return dataObjectStore.get(pOptions.appId)!.get(pOptions.id)?.value as any;
}

export function getDataObjectById<T extends keyof DataObjectModels, K extends string>(pId: T | K, pAppId = 'site'): DataObject<K extends keyof DataObjectModels ? DataObjectModels[T] : any> {
    try {
        if (!dataObjectStore.get(pAppId)?.has(pId)) {
            initDataObjectFromConfig(pId, pAppId);
        }

        return dataObjectStore.get(pAppId)!.get(pId)?.value as any;
    } catch (ex) {
        if (ex?.message && config.isDebug) {
            import('o365-vue-services').then(alertModule => {
                alertModule.alert(ex?.message, 'warning', {
                    autohide: true
                });
            });
        }
        console.error(ex);
    }
}


export function getDataObjectConfigById(dataObjectId: string) {
    return config?.dataObjectConfigs?.get(dataObjectId);
}